// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datatables css + buttons
//
@import url('https://cdn.datatables.net/1.10.22/css/dataTables.bootstrap4.min.css');
@import url('https://cdn.datatables.net/buttons/1.6.5/css/buttons.bootstrap4.min.css');


//Font Awesome
//$fa-font-path: "/cocaiexpress/public/fonts/vendor/font-awesome" !default;
//@import '~font-awesome/scss/font-awesome.scss';

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";